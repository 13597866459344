import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app">
      <header className="header">
        <a className="link" href="/1/index.html">白鹭引擎初探</a>
      </header>
    </div>
  );
}

export default App;
